import { DEFAULT_CURRENCY } from '../utils/currency';
import { DEFAULT_COUNTRY_CODE } from '../utils/constants';
import { normalisePreferencesValues } from '../utils/donationForm';
import htmlDecode from '../utils/htmlDecode';
import fetch from '../utils/fetch';

const debug = require('debug')('cw:api:order.js');

export const getOrderItems = (basketProducts, formValues) => {
  const orderItems = [];
  basketProducts.forEach((item) => {
    const product = item.data;
    const price = product.price[DEFAULT_CURRENCY];
    const preparedProduct = {
      type: item.data.type === 'gifts_bundle' ? 'gifts_bundle' : 'gift',
      purchased_entity: {
        sku: price.sku,
      },
      unit_price: {
        number: price.amount,
        currency_code: price.currency,
      },
    };
    const cards = [...(item.cards ?? []), ...(item.santaLetters ?? [])];

    // Add items without delivery format (corporate/custom donation).
    if (!cards || !cards.length) {
      orderItems.push({
        ...preparedProduct,
        quantity: item.quantity,
      });
      return;
    }

    let postalCount = 0;
    cards.forEach((card) => {
      if (card.type === 'postal') {
        postalCount++;
        return;
      }

      if (card.type === 'email') {
        let sendingTimestamp = null;
        if (card.emailFormData.date) {
          // Convert date string from React-day-picker to Unix timestamp
          // and set time to midnight, i.e. '2020-02-01T09:00:00.000Z' -> 1580515200
          sendingTimestamp = Math.round(Date.parse(card.emailFormData.date) / 1000) - 3600 * 9;
        } else if (DEFAULT_COUNTRY_CODE === 'GB') {
          // TODO: remove condition when IE will use PDF e-cards solution.
          // We set sendingTimestamp to send the PDF by cron and not send it immediately.
          sendingTimestamp = Math.round(Date.now() / 1000);
        }

        // Add email Gifts.
        orderItems.push({
          ...preparedProduct,
          quantity: 1,
          field_card_delivery: 'email',
          // Create ecard with formData on the backend.
          field_card: {
            field_send_to: 'to_recipient',
            field_subject: 'A friend has bought you a Gift',
            field_recipient_email: card.emailFormData.email,
            field_sending_timestamp: sendingTimestamp,
            field_recipient_name: card.emailFormData.name,
            field_message: card.emailFormData.message,
          },
        });
      }

      if (card.type === 'print') {
        orderItems.push({
          ...preparedProduct,
          quantity: 1,
          field_card_delivery: 'email',
          // Create ecard with formData on the backend.
          field_card: {
            field_send_to: 'to_yourself',
            field_subject: 'Your Concern Gift Card',
            field_recipient_email: formValues.email,
            field_sending_timestamp: Math.round(Date.now() / 1000),
            field_recipient_name: formValues.firstName,
            field_message: '',
          },
        });
      }

      if (card.type === 'santa_letter') {
        orderItems.push({
          ...preparedProduct,
          quantity: 1,
          // Currently Santa letter needs to be delivered by Post.
          field_card_delivery: 'postal',
          // Create santa_letter with formData on the backend.
          field_card: {
            type: 'santa_letter',
            field_recipient_name: card.name,
            field_text_plain_1: card.present,
            field_text_plain_2: card.post_scriptum,
          },
        });
      }
    });

    // Add postal Gifts.
    if (postalCount) {
      orderItems.push({
        ...preparedProduct,
        quantity: postalCount,
        field_card_delivery: 'postal',
      });
    }
  });
  return orderItems;
};

// Prepare all Gifts checkout data.
export const prepareOrderPayload = (basketProducts, formValues, coupon) => {
  const orderItems = getOrderItems(basketProducts, formValues);

  // Prepare contact preferences for the backend.
  const profileContactPrefs = {};
  const normalisedPreferencesValues = normalisePreferencesValues(
    formValues.contact_preferences || [],
    formValues.viewedPreferencesOptions,
  );

  Object.keys(normalisedPreferencesValues).forEach((key) => {
    profileContactPrefs[`field_contact_${key}`] = normalisedPreferencesValues[key];
  });

  // We use default country here because country_code is required parameter
  // for Address item in Drupal. Address item contains First name and Last name,
  // therefore First name and Last name won't be saved without country.
  let country = DEFAULT_COUNTRY_CODE;
  let administrativeArea = '';

  if (formValues.address.country) {
    // Administrative area can be either text field or select list.
    if (
      formValues.address.administrativeArea &&
      formValues.address.administrativeArea[formValues.address.country.value]
    ) {
      if (formValues.address.administrativeArea[formValues.address.country.value].value) {
        administrativeArea =
          formValues.address.administrativeArea[formValues.address.country.value].value;
      } else {
        administrativeArea =
          formValues.address.administrativeArea[formValues.address.country.value];
      }
    }
    country = formValues.address.country.value;
  }

  return {
    order: {
      type: 'gift',
      field_source_code: formValues.sourceCode ? formValues.sourceCode.value : '',
      field_giftaid: formValues.giftaid && formValues.giftaid.length,
      order_items: orderItems,
    },
    profile: {
      ...profileContactPrefs,
      field_phone: formValues.phone,
      field_title:
        !formValues.title || formValues.title === 'Prefer not to say' ? '' : formValues.title.value,
      address: {
        given_name: formValues.firstName.trim(),
        family_name: formValues.lastName.trim(),
        country_code: country,
        // The UK address autocomplete will have a select dropdown object.
        address_line1:
          formValues.address.addressLine1 && typeof formValues.address.addressLine1 !== 'string'
            ? formValues.address.addressLine1.label
            : formValues.address.addressLine1,
        address_line2: formValues.address.addressLine2,
        locality: formValues.address.locality,
        administrative_area: administrativeArea,
        // The UK address autocomplete will have a select dropdown object.
        postal_code:
          formValues.address.postalCode && typeof formValues.address.postalCode !== 'string'
            ? formValues.address.postalCode.value
            : formValues.address.postalCode,
      },
    },
    user: {
      mail: formValues.email.trim(),
    },
    coupon: coupon.code,
  };
};

export const submitOrder = (data) =>
  new Promise((resolve, reject) =>
    fetch(`/commerce/order/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      throwNotOk: false,
      body: data,
    })
      .then((response) => {
        resolve({
          orderID: response.statusCode === 201 ? response.body.order_id[0].value : null,
          orderStatus: response.statusCode === 201 ? response.body.state[0].value : null,
          statusCode: response.statusCode,
          errorMessage: response.body.message ? htmlDecode(response.body.message) : '',
        });
      })
      .catch((error) => {
        debug('Could not submit Gift Order.', error);
        reject(error);
      }),
  );
